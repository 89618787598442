import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const COMPANY_INFO = {
  formTitle: "Company",
  formTitleForNew: "New Company",
  path: "/companies",
  listPage: "/companies",
}

export const COMPANY_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "company",
      fieldItems: [
        {
          label: "Company Name",
          type: "text",
          name: "companyName",
          initialFocus: false,
          validationProps: {
            required: "Company Name is required",
            minLength: {
              value: 2,
              message: "Company Name must be more than 2 letters"
            }
          }
        },
        {
          label: "Registered date",
          type: "date",
          name: "registeredDate",
          initialFocus: false,
          validationProps: {
            required: "Registered date is required"
          }
        },
        {
          label: "Remark",
          type: "textarea",
          name: "description",
          required: false,
          initialFocus: false,
          muliselect: false,
        },
      ]
    },
    salesPermissionDetails: {
      sectionType: "Sales Access Rights",
      sectionTypeId: "salesPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 3,
      fieldName: "salesPermission",
      fieldItems: [
        {
          label: "Sales Orders",
          type: "select",
          name: "salesOrder",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Customers",
          type: "select",
          name: "customer",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Sales Quotes",
          type: "select",
          name: "salesQuote",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Sales Return",
          type: "select",
          name: "salesReturn",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Packing",
          type: "select",
          name: "packing",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Shipping",
          type: "select",
          name: "shipping",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
      ]
    },
    purchasePermissionDetails: {
      sectionType: "Purchasing Access Rights",
      sectionTypeId: "purchasingPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 3,
      fieldName: "purchasingPermission",
      fieldItems: [
        {
          label: "Purchase Orders",
          type: "select",
          name: "purchaseOrder",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Suppliers",
          type: "select",
          name: "supplier",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Purchase Return",
          type: "select",
          name: "purchaseReturn",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Operating Expense",
          type: "select",
          name: "operatingExpense",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Expense Type",
          type: "select",
          name: "expenseType",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
      ]
    },
    inventoryPermissionDetails: {
      sectionType: "Stock Access Rights",
      sectionTypeId: "inventoryPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 5,
      fieldName: "inventoryPermission",
      fieldItems: [
        {
          label: "Products",
          type: "select",
          name: "item",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Categories",
          type: "select",
          name: "itemGroup",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Stock Tranfers",
          type: "select",
          name: "stockTransfer",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Stock Adjustment",
          type: "select",
          name: "stockAdjustment",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Stock Count",
          type: "select",
          name: "stockCount",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Unit of Measures",
          type: "select",
          name: "unitOfMeasure",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Branches",
          type: "select",
          name: "location",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Item Taxes",
          type: "select",
          name: "itemTax",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Manufacture Order",
          type: "select",
          name: "workOrder",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Bill of Materials",
          type: "select",
          name: "billOfMaterials",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        }
      ]
    },
    reportPermissionDetails: {
      sectionType: "Report Access Rights",
      sectionTypeId: "reportPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 2,
      fieldName: "reportPermission",
      fieldItems: [
        {
          label: "Reports",
          type: "select",
          name: "report",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        }
      ]
    },
    systemPermissionDetails: {
      sectionType: "System Access Rights",
      sectionTypeId: "systemPermissionDetails",
      multiForm: false,
      expandable: true,
      addButton: true,
      breakPoint: 1,
      fieldName: "systemPermission",
      fieldItems: [
        {
          label: "Accounts",
          type: "select",
          name: "account",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        },
        {
          label: "Company",
          type: "select",
          name: "company",
          required: false,
          initialFocus: false,
          defaultValue: { label: 'Enable', value: 1 },
          url: "/featureflagoptions"
        }
      ]
    },
  },
  watchFields: []
}
