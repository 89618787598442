import './App.css';

import { Switch, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import ProtectedLayout from './components/Layout/protected-layout/protected-layout.componenet';
import PublicLayout from './components/Layout/public-layout/public-layout.componenet';
import { setIsLoggedIn } from './redux/user/user.actions';
import { setWindowWidth } from './redux/navigation/navigation.actions';
import ModalManager from './components/modal-manager/modal-manager.componenet';
import { setWindowHeight } from './redux/modal/modal.actions';
import AlertBox from './components/alertbox/alert-box.componenet';
import { authTokenSelector } from './redux/user/user.reselect';
import { createStructuredSelector } from 'reselect';
import useAccessRight from './effects/use-access-right';

const App = ({
  setIsLoggedIn,
  setWindowWidth,
  setWindowHeight,
  authToken
}) => {
  window.addEventListener('resize', (event) => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  })

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    const loggedInUser = localStorage.getItem('user');

    if (loggedInUser) {
      setIsLoggedIn()
    }
  }, []);

  return (
   <div className="App">
      <Switch>
        <Route path='/home' component={ProtectedLayout} />
        <Route path='/logout' component={ProtectedLayout} />
        <Route path='/access-denied' component={ProtectedLayout} />
        <Route path='/loggedintest' component={ProtectedLayout} />
        <Route path='/detail-print' component={ProtectedLayout} />

        <Route exact path='/companies' component={ProtectedLayout} />
        <Route exact path='/companies/new' component={ProtectedLayout} />
        <Route exact path='/companies/:uuid' component={ProtectedLayout} />


        <Route path='/' component={PublicLayout} />
      </Switch>
      <ModalManager />
      <AlertBox />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setIsLoggedIn: () => dispatch(setIsLoggedIn()),
  setWindowWidth: (width) => dispatch(setWindowWidth(width)),
  setWindowHeight: (height) => dispatch(setWindowHeight(height))
})

export default connect(null, mapDispatchToProps)(App);
