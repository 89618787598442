import { IoIosBusiness } from "react-icons/io";

export const navItems1 = [
    {
        title: "Tenants",
        icon: IoIosBusiness,
        items: [
            {
                text: 'Companies',
                description: "Manage comapnies and account",
                icon: IoIosBusiness,
                page: '/companies'
            }
        ]
    },
]




