import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from '../../../pages/home/home.component';
import Logout from '../../logout/logout.component';
import ProtectedHeader from '../protected-header/protected-header.componenet';
import ProtectedFooter from '../protected-footer/protected-footer.componenet';
import LoggedInTest from '../../../pages/log-in/logged-in-test';
import ProtectedRoute from '../protected-route/protected-route.componenet';
import DetailPrintDisplay from '../../../pages/detail-print/detail-print-display.component';

import CompanyList from '../../../pages/company/company-list/companies-list.component';
import CompanyForm from '../../../pages/company/company-form/company-form.component';

import AccessDenied from '../../../pages/access-denied/access-denied.component';


const ProtectedLayout = ({
    noNavs
}) => <>
        {!noNavs && <ProtectedHeader />}
        <Switch>
            <ProtectedRoute exact path="/home" component={Home} appName={false} />
            <ProtectedRoute exact path="/logout" component={Logout} />
            <Route path='/access-denied' component={AccessDenied} />
       
            <ProtectedRoute exact path="/loggedintest" component={LoggedInTest} />
            <ProtectedRoute exact path="/detail-print" component={DetailPrintDisplay} />
           
            <ProtectedRoute exact path="/companies" component={CompanyList} key={Date.now()} appName={`company`} />
            <ProtectedRoute exact path="/companies/new" component={CompanyForm} key={Date.now()} isNew={true} appName={`company`} />
            <ProtectedRoute exact path="/companies/:uuid" component={CompanyForm} key={Date.now()} appName={`company`} />
        </Switch>
        {!noNavs && <ProtectedFooter />}
    </>

export default ProtectedLayout;