import { format } from 'date-fns';
import ListActions from '../../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../../components/list-table-main-cell/list-table-main-cell.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';
import { useMediaQuery } from 'react-responsive';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

const ENDPOINT = "/items";

export const COMPNANIES_COLUMNS = [
    {
        Header: "Company Name",
        accessor: "companyName",
        style: {
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2
        },
        Cell: ({ value, row }) => {
            const uuid = row.original.uuid;
            return (
                <ListTableMainCell
                    value={value}
                    uuid={uuid}
                />
            )
        }
    },
    {
        Header: "Registered Date",
        accessor: "registeredDate",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return format(new Date(value), 'dd/MM/yyyy');
        }
    },
    {
        Header: "Remark",
        accessor: "remark",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return displayCellValue(value);
        }
    },
]