import { FaSignOutAlt } from "react-icons/fa";
import { IoIosBusiness } from "react-icons/io";

export const NAV_ITEMS = [
    {
        itemName: "Home",
        page: '/home'
    },
    {
        itemName: "Tenants",
        dropDownItems: [
            {
                id: 'list_sales_orders',
                icon: IoIosBusiness,
                text: 'Companies',
                modal: false,
                page: '/companies'
            },
        ]
    },
]

export const SETTING_NOTFICATION_BAR = [
    {
        itemName: "Settings",
        dropDownItems: [
            {
                id: 'logout',
                icon: FaSignOutAlt,
                text: 'logout',
                modal: false,
                page: '/logout',
                breakpoint: true,
                inverted: true
            },
        ]
    },
]



